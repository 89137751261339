import { useState, useEffect } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Journey() {
  const [aosOffset, setAosOffset] = useState(250); // State for AOS offset
  useEffect(() => {
    AOS.init({ offset: aosOffset }); // Initialize AOS with current offset state
  }, [aosOffset]);

  const [showAll, setShowAll] = useState(false); // State to manage showing all experiences
  const experiences = [
    {
      period: "Apr 2024 - Jul 2024",
      title: "Internship | Backend Developer",
      organization: "PT Lentera Bangsa Benderang (Binar Labs)",
      details: [
        "Developed the backend for a Job Marketplace with three types of users: Job Seeker, Company, and Admin, using the Go Programming Language, resulting in a 30% improvement in system efficiency.",
        "Prepared the project boilerplate implementing a clean code architecture within 1 day, reducing initial setup time by 50%.",
        "Designed an ERD tailored to meet the application's requirements, which improved database performance by 20%.",
        "Created a login API and implemented JWT authentication, enhancing security and user experience, leading to a 25% increase in successful login attempts.",
        "Documented the API using Swagger, improving the development workflow and collaboration, which accelerated the onboarding process for new developers by 40%.",
        "Developed and maintained cloud server infrastructure using GCP, focusing on deployment and bug fixing throughout the development process, leading to a 35% increase in system stability and reliability.",
      ],
    },
    {
      period: "May 2024 - Jul 2024",
      title: "Certificate Mobile App React Native",
      organization: "Digital Talent Scholarship x Progate",
      details: [
        "Learned state management, props, and components, achieving 100% understanding.",
        "Developed websites using React components, resulting in 85% proficiency in creating dynamic web applications.",
        "Created contact forms with data validation, enhancing accuracy and reliability by 90%.",
        "Applied React knowledge through hands-on projects like ToDo, Note App, and WeatherApp, gaining practical experience and proficiency in real-world scenarios.",
        "Studied React Native lifecycle methods and mobile UI component development, achieving an 85% proficiency in mobile application development.",
        "Integrated APIs using Axios and developed a React Native + TypeScript application, demonstrating advanced skills and achieving 95% proficiency in application development.",
      ],
    },
    {
      period: "Sep 2023 - Nov 2023",
      title: "Bootcamp Backend Javascript",
      organization: "Digital Talent Scholarship x Binar Academy",
      details: [
        "Create a simple program that performs routing, middleware, and views using Express.js and implements token-based authentication using JWT.",
        "Develop features for image upload and document upload (PDF).",
        "Implement the generation and download of QR Codes and integrate with cloud storage services like Imagekit.io.",
        "Practice sending emails through a Node.js application using Nodemailer.",
        "Deploy the Express.js application using Railway.app.",
        "Create API documentation using swagger-ui-express.",
      ],
    },
    {
      period: "Jun 2023 - Aug 2023",
      title: "Certificate IT Support",
      organization: "Digital Talent Scholarship x Coursera",
      details: [
        "Fundamentals of Technical Support",
        "The Ins and Outs of Computer Networks",
        "Operating Systems: Becoming an Empowered User",
        "System Administration and IT Infrastructure Services",
        "IT Security: Defense Against Digital Crimes",
      ],
    },
    {
      period: "Mar 2023 - Apr 2023",
      title: "Certificate Intro to Front-end Web Developer",
      organization: "Digital Talent Scholarship x Progate",
      details: [
        "Developed HTML structures and implemented CSS for layout and animations, contributing to the creation of visually engaging websites, leading to a 25% increase in user satisfaction.",
        "Showcased JavaScript proficiency through the creation of functional applications handling data manipulation and user interactions, resulting in a 30% improvement in application performance.",
        "Gained foundational knowledge in front-end web development, including HTML, CSS, and JavaScript, which increased development speed by 20%.",
        "Completed hands-on projects to apply theoretical knowledge in practical scenarios, enhancing problem-solving skills by 25%.",
        "Demonstrated the ability to create responsive and interactive web pages, improving user engagement and experience by 30%.",
      ],
    },
    {
      period: "Mar 2021 - May 2021",
      title: "Internship | UI / UX Designer",
      organization: "Kampus Dosen Jualan",
      details: [
        "Collaborated to refine UX design, leading to a notable 25% increase in perceived value based on user feedback, driving a 20% boost in user engagement.",
        "Identified and implemented a dynamic WordPress theme, resulting in a remarkable 40% surge in website traffic within the initial month, enhancing online visibility and reach.",
        "Overhauled website layout and fine-tuned elements using Elementor, contributing to a significant 40% reduction in bounce rate and a commendable 25% increase in average user engagement time per page, augmenting the site's appeal and usability.",
        "Improved user experience by optimizing website loading speed by an impressive 30%, significantly enhancing site accessibility and user satisfaction, resulting in a 20% increase in return visitors.",
      ],
    },
  ];

  // Logic to determine how many experiences to show
  const visibleExperiences = showAll ? experiences : experiences.slice(0, 4);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    // Update AOS offset based on showAll state
    setAosOffset(showAll ? 250 : 0);
  };

  return (
    <div
      id="journey"
      className="max-w-7xl mx-auto flex flex-col justify-center items-center text-white text-center min-h-screen py-28"
    >
      <div className="text-center pb-10">
        <h2
          data-aos="fade-up"
          data-aos-offset={aosOffset} // Dynamic AOS offset
          data-aos-duration="1000"
          className="text-2xl md:text-3xl py-2 font-bold text-[#CCD6F6]"
        >
          <span className="font-mono font-normal text-xl md:text-2xl text-[#64ffda]">
            03.
          </span>
          Journey
        </h2>
      </div>
      <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical text-start mx-2 pb-16 h-full">
        {visibleExperiences.map((experience, index) => (
          <li
            key={index}
            data-aos="fade-up"
            data-aos-offset={aosOffset} // Dynamic AOS offset
            data-aos-duration="1000"
            className="hover:z-50"
          >
            {index !== 0 && <hr className="bg-[#a8b2d1]" />}
            <div className="timeline-middle text-[#64ffda]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div
              className={`timeline-${
                index % 2 === 0 ? "start md:text-end" : "end"
              } text-[#a8b2d1] mt-1.5 mx-2`}
            >
              <time className="font-mono italic">{experience.period}</time>
              <div className="text-lg font-black">
                <HoverCard>
                  <HoverCardTrigger className="hover:text-[#64ffda] duration-500 border-b border-transparent hover:border-[#64ffda] cursor-pointer">
                    {experience.title}
                  </HoverCardTrigger>
                  <HoverCardContent className="w-[500px]">
                    <ul className="list-disc text-sm mx-3 text-start">
                      {experience.details.map((detail, idx) => (
                        <li key={idx}>{detail}</li>
                      ))}
                    </ul>
                  </HoverCardContent>
                </HoverCard>
              </div>
              <p className="mb-5">{experience.organization}</p>
            </div>
            <hr className="bg-[#a8b2d1]" />
          </li>
        ))}
      </ul>

      {/* Show More / Show Less buttons */}
      <div className="text-center">
        <button
          id="show"
          className="hover:-translate-y-1 active:translate-y-0 duration-300 transition ease-in-out hover:shadow-[0_4px_0_0_#64ffda] active:shadow-[none] rounded-md py-4 px-8 font-mono text-[#64ffda] border border-solid border-[#64ffda]"
          onClick={toggleShowAll}
        >
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

